import React from 'react';
import './styles.scss';
import {useDevice} from "../../../../hooks/useDimensions";

const ValuesSection = () => {
  const { isMobile } = useDevice();

  return (
    <div className="values-section">
      <div className="values-section__title">Наши ценности</div>

      <div className="values-section__content">
        {isMobile ? (
          <div className="values-image">
            <img src="/images/hearts.jpg" alt="Ценности" />
          </div>
          ) : (
          <div className="values-image">
            <img src="/images/heart.jpg" alt="Ценности" />
          </div>
        )}
        <div className="values-content">
          <div className="values-text">
            <p>
              В La Rouletterie мы верим, что каждый заслуживает насладиться исключительным вкусом настоящей выпечки и десертов. Наше путешествие началось с мечты нашего основателя, который, вернувшись из Москвы, где он открыл для себя мир премиальных продуктов и совершенствовал своё мастерство в пекарском искусстве, решил поделиться этим опытом с родным городом. Мы стремимся показать, что высококачественная выпечка и десерты — это не просто еда, а искусство, доступное каждому.
            </p>
            <p>
              Наши ценности основаны на неизменном качестве и внимании к деталям. Используя только лучшие ингредиенты и применяя методы, переданные нам известным московским шеф-поваром, мы гарантируем качество каждого изделия La Rouletterie.
            </p>
            <p>
              Мы нацелены на создание не просто пекарни, а места, где каждый посетитель может ощутить радость и удовольствие от вкуса наших десертов.
            </p>
            <p>
              В La Rouletterie мы не просто делаем выпечку — мы делимся радостью и теплом с каждым клиентом, стремясь сделать ваш день немного слаще и счастливее.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuesSection;
